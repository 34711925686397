import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import FormSpacer from "@client/components/form/helpers/FormSpacer";
import TextFieldInput from "@client/components/form/inputs/TextFieldInput";
import CloseIcon from "@mui/icons-material/Close";
import { Link as MuiLink, Stack, useMediaQuery } from "@mui/material";
import { Container, Paper, Grid, Typography, IconButton } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Label from "@client/components/form/helpers/Label";
import {
  DirectorResults,
  OneManBusinessResults,
  PensionFormData,
  PersonalFormData,
} from "types";
import CheckBoxInput from "@client/components/form/inputs/CheckBoxInput";
import axios from "axios";
import { FormText } from "@public/cms/types";
import { Dispatch, SetStateAction, useState } from "react";
import LoadingSpinner from "@client/components/LoadingSpinner";
import { theme } from "@styles/Theme";

export default function PersonalFormDialog(props: {
  locale: "nl" | "fr";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resultData: OneManBusinessResults | DirectorResults;
  pensionFormData: PensionFormData;
  formText: FormText;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarVariant: Dispatch<SetStateAction<"success" | "error">>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const form = useForm<PersonalFormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: {
      termsOfAgreement: false,
      contactExpert: true,
      infoStartingCompany: true,
    },
  });

  const handleDialogClose = () => {
    props.setOpen(false);
    // form.reset({}, { keepDefaultValues: true });
    form.resetField("termsOfAgreement");
    form.resetField("contactExpert");
    form.resetField("infoStartingCompany");
  };

  const onSubmit: SubmitHandler<PersonalFormData> = async (data) => {
    // console.log(data);

    setLoading(true);

    if (props.pensionFormData.selfEmployedType === "director") {
      delete data["infoStartingCompany"]; //? this Simply removes the property from the object so it won't even reach the backend, however i'd recommend putting this check in the backend itself
    }

    const formattedData = {
      pensionFormData: props.pensionFormData,
      personalFormData: data,
      resultsData: props.resultData,
      locale: props.locale,
    };
    // console.log(formattedData);
    try {
      const apiResponse = await axios.post(
        "/api/personalFormHandler",
        formattedData,
        {
          headers: { "x-api-key": "148A3FECE6785" },
        }
      );
      if (apiResponse.status === 200) {
        props.setSnackbarVariant("success");
        props.setSnackbarOpen(true);
      }
    } catch (error) {
      props.setSnackbarVariant("error");
      props.setSnackbarOpen(true);
    } finally {
      setLoading(false);
      handleDialogClose();
    }
  };

  return (
    <Dialog
      fullScreen={fullscreen}
      open={props.open}
      onClose={handleDialogClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper square sx={{ backgroundColor: "#F2F2F2" }}>
        <Container maxWidth="xs" sx={{ mt: 3 }}>
          <FormProvider {...form}>
            <form>
              <FormSpacer>
                <Box display={"flex"} flexDirection={"row"}>
                  <Typography>{props.formText.modalTitle}</Typography>
                  <IconButton onClick={handleDialogClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"left"}
                  flexDirection={"row"}
                  pb={0}
                >
                  <Typography variant="body2">
                    {props.formText.requiredFieldsText}
                  </Typography>
                </Box>
                <Grid container rowSpacing={1.5} direction={"column"}>
                  <Grid item sm={12}>
                    <Label label={props.formText.email + "*"} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="email"
                      rules={{
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                          message: `${props.formText.wrongEmail}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.firstName + "*"} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="firstName"
                      rules={{ required: `${props.formText.required}` }}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Label label={props.formText.lastName + "*"} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="lastName"
                      rules={{ required: `${props.formText.required}` }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.companyName} />
                    <TextFieldInput
                      name="companyName"
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.enterpriseNumber} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="enterpriseNumber"
                      rules={{
                        pattern: {
                          value: /[0-9]{10}|([0-9]{4}.[0-9]{3}.[0-9]{3})/gm,
                          message: `${props.formText.wrongEnterpriseNumber}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.phoneNumber + "*"} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="phone"
                      rules={{
                        required: `${props.formText.required}`,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.zipCode + "*"} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="zipCode"
                      rules={{
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[\d]{4}$/,
                          message: `${props.formText.wrongZipCode}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormSpacer removePadding>
                      <CheckBoxInput
                        name="termsOfAgreement"
                        rules={{
                          required: "Je moet de privacyverklaring aanvaarden.",
                        }}
                        muiCheckboxProps={{ inputProps: { readOnly: loading } }}
                        disabled={loading}
                        defaultValue={false}
                        label={
                          <div>
                            {props.formText.modalPrivacyTextBeforeLink}
                            <MuiLink
                              target="_blank"
                              rel="noopener"
                              href={props.formText.modalPrivacyTextLink}
                              color="secondary"
                            >
                              {props.formText.modalPrivacyTextLinkText}
                            </MuiLink>
                            .
                          </div>
                        }
                      />
                      <CheckBoxInput
                        disabled={loading}
                        muiCheckboxProps={{
                          inputProps: { readOnly: loading },
                        }}
                        name="contactExpert"
                        defaultValue={true}
                        label={<div>{props.formText.modalContactExpert}</div>}
                      />
                      {props.pensionFormData.selfEmployedType ==
                        "oneManBusiness" && (
                        <CheckBoxInput
                          disabled={loading}
                          muiCheckboxProps={{
                            inputProps: { readOnly: loading },
                          }}
                          name="infoStartingCompany"
                          defaultValue={true}
                          label={
                            <div>
                              {props.formText.modalInfoStartCompany}
                              <MuiLink
                                target="_blank"
                                rel="noopener"
                                href={props.formText.modalInfoStartiStartLink}
                                color="secondary"
                              >
                                {props.formText.modalInfoStartiStartLinkText}
                              </MuiLink>
                              .
                            </div>
                          }
                        />
                      )}
                    </FormSpacer>
                  </Grid>
                </Grid>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  disabled={!form.watch(`termsOfAgreement`)}
                  loading={loading}
                  loadingPosition="start"
                  loadingIndicator={<LoadingSpinner />}
                  onClick={form.handleSubmit(onSubmit)}
                  sx={{ p: 2 }}
                >
                  <Typography
                    fontSize={"1rem"}
                    fontWeight={700}
                    color={"white"}
                  >
                    {loading
                      ? props.formText.modalButtonLoadingText
                      : props.formText.modalButtonSubmit}
                  </Typography>
                </LoadingButton>
              </FormSpacer>
            </form>
          </FormProvider>
        </Container>
      </Paper>
    </Dialog>
  );
}
