import {
  FormControl,
  SelectProps as SelectPropsMui,
  FormHelperText,
  Select,
  ListSubheader,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { useFormContext, Controller, RegisterOptions } from "react-hook-form";

type Options = { label: string; value: string }[];

export default function DropdownInput(props: {
  label?: string | React.ReactNode;
  name: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  muiSelectProps?: Omit<SelectPropsMui, "name" | "defaultValue" | "multiple">;
  options: Options;
}) {
  const form = useFormContext();
  return (
    <Controller
      defaultValue={props.defaultValue ?? ""}
      control={form.control}
      name={props.name}
      rules={props.rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl fullWidth>
            <InputLabel sx={{ backgroundColor: "white", pr: 1 }}>
              {props.label}
            </InputLabel>
            <Select
              {...field}
              error={fieldState.error ? true : false}
              label={props.label}
              {...props.muiSelectProps}
              multiple={false}
            >
              {props.options.map((el: any) => {
                if (el.value === "GROUP") {
                  return (
                    <ListSubheader
                      key={el.value + Math.random().toString(36).substring(7)}
                    >
                      {el.label}
                    </ListSubheader>
                  );
                } else {
                  return (
                    <MenuItem value={el.value} key={el.value}>
                      {el.label}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {fieldState.error && (
              <FormHelperText
                error
                sx={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    <ReportProblemRoundedIcon sx={{ mr: 1 }} fontSize="small" />
                    {fieldState.error?.message}
                  </>
                }
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
}
