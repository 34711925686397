import {
  FormControl,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormHelperText,
} from "@mui/material";
import { useFormContext, Controller, RegisterOptions } from "react-hook-form";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

type Options = {
  value: string;
  label: string | JSX.Element;
}[];

export default function ToggleButtonInput(props: {
  name: string;
  fullWidth?: boolean;
  tickbox?: boolean;
  defaultValue?: string[];
  rules?: RegisterOptions;
  muiToggleButtonProps?: Omit<MuiToggleButtonProps, "name" | "value">;
  muiToggleButtonGroupProps?: Omit<
    MuiToggleButtonGroupProps,
    "value" | "exclusive" | "fullWidth"
  >;
  muiCheckboxProps?: MuiCheckboxProps;
  options: Options;
}) {
  const form = useFormContext();
  const defaultValue = props.defaultValue ?? [];

  return (
    <Controller
      control={form.control}
      defaultValue={defaultValue}
      name={props.name}
      rules={props.rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl
            fullWidth={props?.fullWidth}
            error={fieldState.error ? true : false}
          >
            <MuiToggleButtonGroup
              exclusive={true}
              {...field}
              {...props.muiToggleButtonGroupProps}
              fullWidth={props?.fullWidth}
              onChange={(event, value) => {
                return field.onChange(value);
              }}
            >
              {props.options.map((el) => {
                return (
                  <MuiToggleButton key={el.value} value={el.value}>
                    {el.label}
                    {props.tickbox && (
                      <MuiCheckbox
                        checked={field.value.includes(el.value)}
                        value={el.value}
                      />
                    )}
                  </MuiToggleButton>
                );
              })}
            </MuiToggleButtonGroup>
            {fieldState.error && (
              <FormHelperText
                error
                sx={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    <ReportProblemRoundedIcon sx={{ mr: 1 }} fontSize="small" />
                    {fieldState.error?.message}
                  </>
                }
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
}
