import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Box, Typography } from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import CheckIcon from "@mui/icons-material/Check";
import selectText from "helpers/selectText";
import { MainText } from "@public/cms/types";
import mainJson from "@public/cms/main.json";

export default function CustomSnackbar(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  variant: "success" | "error";
  locale: "nl" | "fr";
}) {
  const text = selectText(mainJson, props.locale) as MainText;
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    props.setOpen(false);
  };

  const bgColor = props.variant == "error" ? "#D2112E" : "#3AD06A";
  const icon =
    props.variant == "error" ? (
      <ReportProblemRoundedIcon htmlColor="white" />
    ) : (
      <CheckIcon htmlColor="white" />
    );

  const message =
    props.variant == "error" ? (
      <Box display={"flex"} flexDirection="column">
        <Typography textAlign={"left"}>{text.errorPartOne}</Typography>
        <Typography textAlign={"left"}>{text.errorPartTwo}</Typography>
      </Box>
    ) : (
      <Typography textAlign={"left"}>{text.success}</Typography>
    );

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.variant === "success" ? 5000 : 8000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Box
        display={"flex"}
        sx={{ width: "450px", height: 65 }}
        boxShadow={" 0px 4px 4px rgba(0, 0, 0, 0.25)"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ width: 45, bgcolor: bgColor }}
        >
          {icon}
        </Box>
        <Box
          paddingLeft={2}
          paddingRight={2}
          display="flex"
          justifyContent="left"
          alignItems="center"
          sx={{ width: "100%", bgcolor: "white" }}
        >
          {message}
        </Box>
      </Box>
    </Snackbar>
  );
}
