import React from "react";
import { useTheme } from "@mui/material/styles";
import { Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export type LabelProps = {
  label: string;
  tooltip?: string | React.ReactNode;
  tooltipIcon?: JSX.Element;
};

export default function Label(props: LabelProps) {
  const theme = useTheme();
  const tooltipIcon = props.tooltipIcon ?? (
    <InfoOutlinedIcon htmlColor={"rgba(0, 0, 0, 0.5)"} />
  );
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        color={"rgba(0, 0, 0, 0.5)"}
        style={{
          marginBottom: theme.spacing(0.3),
          marginRight: theme.spacing(1),
          textTransform: "uppercase",
        }}
      >
        {props.label}
      </Typography>
      {props.tooltip && (
        <Tooltip
          sx={{ fontSize: 14 ,marginBottom: theme.spacing(0.4) }}
          title={props.tooltip}
        >
          {tooltipIcon}
        </Tooltip>
      )}
    </div>
  );
}
