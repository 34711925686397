import { Box, Paper, SxProps, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material";

function FormSpacer({
  removePadding,
  children,
}: {
  removePadding?: boolean;
  children: React.ReactNode | React.ReactNode[];
}) {
  const { spacing } = useTheme();
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: spacing(2.5),
        columnGap: spacing(3),
        padding: removePadding ? 0 : spacing(3),
      }}
    >
      {children}
    </Box>
  );
}

export default FormSpacer;
