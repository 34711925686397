import FormSpacer from "@client/components/form/helpers/FormSpacer";
import Label from "@client/components/form/helpers/Label";
import DropdownInput from "@client/components/form/inputs/DropdownInput";
import TextFieldInput from "@client/components/form/inputs/TextFieldInput";
import ToggleButtonInput from "@client/components/form/inputs/ToggleButtonInput";
import { Button, Grid, InputAdornment, Paper } from "@mui/material";
import { Container } from "@mui/system";
import { FormText, MainText } from "@public/cms/types";
import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  DirectorFormHandlerRequestBody,
  DirectorResults,
  OneManBusinessFormHandlerRequestBody,
  OneManBusinessResults,
  PensionFormData,
} from "types";

export default function PensionForm(props: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: Boolean;
  setApiResponse: Dispatch<OneManBusinessResults | DirectorResults>;
  setPageState: Dispatch<SetStateAction<"form" | "result">>;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarVariant: Dispatch<SetStateAction<"error" | "success">>;
  setPensionFormData: Dispatch<SetStateAction<PensionFormData | undefined>>;
  mainText: MainText;
  formText: FormText;
}) {
  const form = useForm<PensionFormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const onFormSubmit: SubmitHandler<PensionFormData> = async (data) => {
    props.setLoading(true);
    // console.log(data);

    let formattedData:
      | DirectorFormHandlerRequestBody
      | OneManBusinessFormHandlerRequestBody
      | undefined = undefined;

    if (data.selfEmployedType === "director") {
      formattedData = {
        selfEmployedType: "director",
        currentAge: data.currentAge,
        startWorkingAge: data.startWorkingAge,
        startSelfEmployedAge: data.startSelfEmployedAge ?? data.startWorkingAge,
        startCompanyAge: data.startCompanyAge ?? data.startWorkingAge,
        grossIncome: data.grossIncome,
        inLegalCohabitation: data.inLegalCohabitation,
        hasMortgageLoan: "true",
        investorProfile: data.investorProfile,
      };
      try {
        const apiResponse = await axios.post(
          "/api/directorFormHandler",
          formattedData,
          { headers: { "x-api-key": "148A3FECE6785" } }
        );
        if (apiResponse.status === 200) {
          props.setPensionFormData(formattedData);
          props.setApiResponse(apiResponse.data);
          props.setLoading(false);
          props.setPageState("result");
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.log(error);
        props.setSnackbarVariant("error");
        props.setLoading(false);
        props.setSnackbarOpen(true);
      }
    } else {
      formattedData = {
        selfEmployedType: "oneManBusiness",
        currentAge: data.currentAge,
        startWorkingAge: data.startWorkingAge,
        inLegalCohabitation: data.inLegalCohabitation,
        grossIncome: data.grossIncome,
        grossCosts: data.grossCosts ?? "0",
        hasMortgageLoan: data.hasMortgageLoan,
        investorProfile: data.investorProfile,
      };
      try {
        const apiResponse = await axios.post(
          "/api/oneManBusinessFormHandler",
          formattedData,
          {
            headers: { "x-api-key": "FEBF4AC71B91F" },
          }
        );
        if (apiResponse.status === 200) {
          props.setPensionFormData(formattedData);
          props.setApiResponse(apiResponse.data);
          // console.log(apiResponse.data);
          props.setLoading(false);
          props.setPageState("result");
        }
      } catch (error) {
        console.log(error);
        props.setSnackbarVariant("error");
        props.setLoading(false);
        props.setSnackbarOpen(true);
      }
    }
  };
  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 3 }}>
        <Paper square sx={{ backgroundColor: "#F2F2F2" }}>
          <FormProvider {...form}>
            <form>
              <FormSpacer>
                <Grid container rowSpacing={1.8}>
                  <Grid item xs={12}>
                    <Label label={props.formText.selfEmployedTypeQuestion} />
                    <ToggleButtonInput
                      name="selfEmployedType"
                      fullWidth
                      options={[
                        {
                          label: `${props.formText.companyDirector}`,
                          value: "director",
                        },
                        {
                          label: `${props.formText.oneManBusiness}`,
                          value: "oneManBusiness",
                        },
                      ]}
                      rules={{ required: `${props.formText.required}` }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Label label={props.formText.ageQuestion} />
                    <TextFieldInput
                      name="currentAge"
                      TextFieldProps={{
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              {props.formText.year}
                            </InputAdornment>
                          ),
                        },
                      }}
                      rules={{
                        min: {
                          value: "18",
                          message: `${props.formText.notBelow} 18.`,
                        },
                        max: {
                          value: "65",
                          message: `${props.formText.notAbove} 65.`,
                        },
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[0-9]*[,|.]?[0-9]*$/,
                          message: `${props.formText.onlyNumber}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Label label={props.formText.startWorkingQuestion} />
                    <TextFieldInput
                      name="startWorkingAge"
                      TextFieldProps={{
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              {props.formText.year}
                            </InputAdornment>
                          ),
                        },
                      }}
                      rules={{
                        validate: {
                          validateInput: () =>
                            Number(form.watch("startWorkingAge")) <=
                              Number(form.watch("currentAge")) ||
                            `${props.formText.notAbove} ${form.watch(
                              "currentAge"
                            )}.`,
                          secondCheck: () =>
                            Number(form.watch("startWorkingAge")) >= 16 ||
                            `${props.formText.notBelow} 16.`,
                        },
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[0-9]*[,|.]?[0-9]*$/,
                          message: `${props.formText.onlyNumber}`,
                        },
                      }}
                    />
                  </Grid>
                  {form.watch("selfEmployedType") === "director" && (
                    <>
                      <Grid item xs={12}>
                        <Label
                          label={props.formText.startSelfEmployedQuestion}
                        />
                        <TextFieldInput
                          name="startSelfEmployedAge"
                          TextFieldProps={{
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  {props.formText.year}
                                </InputAdornment>
                              ),
                            },
                          }}
                          rules={{
                            validate: {
                              validateInput: () =>
                                Number(form.watch("startSelfEmployedAge")) <=
                                  Number(form.watch("currentAge")) ||
                                `${props.formText.notAbove} ${form.watch(
                                  "currentAge"
                                )}.`,
                              secondCheck: () =>
                                Number(form.watch("startSelfEmployedAge")) >=
                                  Number(form.watch("startWorkingAge")) ||
                                `${props.formText.notBelow} ${form.watch(
                                  "startWorkingAge"
                                )}.`,
                            },
                            required: `${props.formText.required}`,
                            pattern: {
                              value: /^[0-9]*[,|.]?[0-9]*$/,
                              message: `${props.formText.onlyNumber}`,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Label label={props.formText.startCompanyQuestion} />
                        <TextFieldInput
                          name="startCompanyAge"
                          TextFieldProps={{
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  {props.formText.year}
                                </InputAdornment>
                              ),
                            },
                          }}
                          rules={{
                            validate: {
                              secondCheck: () =>
                                Number(form.watch("startSelfEmployedAge")) <=
                                  Number(form.watch("startCompanyAge")) ||
                                `${props.formText.notBelow} ${form.watch(
                                  "startSelfEmployedAge"
                                )}`,
                              validateInput: () =>
                                Number(form.watch("startWorkingAge")) <=
                                  Number(form.watch("startCompanyAge")) ||
                                `${props.formText.notBelow} ${form.watch(
                                  "startWorkingAge"
                                )}`,
                              thirdCheck: () =>
                                Number(form.watch("startCompanyAge")) <=
                                  Number(form.watch("currentAge")) ||
                                `${props.formText.notAbove} ${form.watch(
                                  "currentAge"
                                )}`,
                            },
                            required: `${props.formText.required}`,
                            pattern: {
                              value: /^[0-9]*[,|.]?[0-9]*$/,
                              message: `${props.formText.required}`,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Label
                      label={props.formText.yearlyBrutoIncomeQuestion}
                      tooltip={props.formText.infoYearlyBrutoIncomeQuestion}
                    />
                    <TextFieldInput
                      name="grossIncome"
                      TextFieldProps={{
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                        },
                      }}
                      rules={{
                        validate: {
                          biggerThan: () => {
                            if (
                              form.watch("selfEmployedType") ===
                              "oneManBusiness"
                            ) {
                              return (
                                Number(form.watch("grossIncome")) > 22000 ||
                                `${props.formText.biggerThen} 22000 euro.`
                              );
                            } else {
                              return (
                                Number(form.watch("grossIncome")) > 27100 ||
                                `${props.formText.biggerThen} 27100 euro.`
                              );
                            }
                          },
                        },
                        pattern: {
                          value: /^[0-9]*[,|.]?[0-9]*$/,
                          message: `${props.formText.onlyNumber}`,
                        },
                        required: `${props.formText.required}`,
                      }}
                    />
                  </Grid>
                  {form.watch("selfEmployedType") == "oneManBusiness" && (
                    <>
                      <Grid item xs={12}>
                        <Label
                          label={props.formText.yearlyBrutoCostQuestion}
                          tooltip={props.formText.infoYearlyBrutoCostQuestion}
                        />

                        <TextFieldInput
                          name="grossCosts"
                          TextFieldProps={{
                            InputProps: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            },
                          }}
                          rules={{
                            validate: {
                              minCosts: () =>
                                Number(form.watch("grossIncome")) -
                                  Number(form.watch("grossCosts")) >
                                  22000 ||
                                `${props.formText.incomeMinCost} 22000 euro.`,
                            },
                            required: `${props.formText.required}`,
                            pattern: {
                              value: /^[0-9]*[,|.]?[0-9]*$/,
                              message: `${props.formText.onlyNumber}`,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Label label={props.formText.legalCohabitationQuestion} />
                    <ToggleButtonInput
                      name="inLegalCohabitation"
                      fullWidth
                      options={[
                        { label: `${props.formText.yes}`, value: "true" },
                        { label: `${props.formText.no}`, value: "false" },
                      ]}
                      rules={{ required: `${props.formText.required}` }}
                    />
                  </Grid>
                  {form.watch("selfEmployedType") == "oneManBusiness" && (
                    <>
                      <Grid item xs={12}>
                        <Label
                          label={props.formText.loanQuestion}
                          tooltip={props.formText.infoLoanQuestion}
                        />
                        <ToggleButtonInput
                          name="hasMortgageLoan"
                          fullWidth
                          options={[
                            { label: `${props.formText.yes}`, value: "true" },
                            { label: `${props.formText.no}`, value: "false" },
                          ]}
                          rules={{ required: `${props.formText.required}` }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Label
                      label={props.formText.investorProfileQuestion}
                      tooltip={
                        <div>
                          <p>
                            {props.formText.infoInverstorProfileQuestionFirst}
                          </p>
                          <p>
                            {props.formText.infoInverstorProfileQuestionSecond}
                          </p>
                          <p>
                            {props.formText.infoInverstorProfileQuestionThird}
                          </p>
                          <p>
                            {props.formText.infoInverstorProfileQuestionFourth}
                          </p>
                        </div>
                      }
                    />
                    <DropdownInput
                      name="investorProfile"
                      options={[
                        {
                          label: `${props.formText.conservative}`,
                          value: "conservative",
                        },
                        {
                          label: `${props.formText.neutral}`,
                          value: "neutral",
                        },
                        {
                          label: `${props.formText.dynamic}`,
                          value: "dynamic",
                        },
                      ]}
                      rules={{ required: `${props.formText.required}` }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="button"
                  sx={{ maxWidth: 250 }}
                  variant="contained"
                  color="secondary"
                  onClick={form.handleSubmit(onFormSubmit)}
                >
                  {props.formText.formOneButtonSubmit}
                </Button>
              </FormSpacer>
            </form>
          </FormProvider>
        </Paper>
      </Container>
    </>
  );
}
