import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  BarOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import datalabels from "chartjs-plugin-datalabels";
import PersonalFormModal from "./PersonalFormDialog";
import { Dispatch, SetStateAction, useState } from "react";
import { DirectorResults, OneManBusinessResults, PensionFormData } from "types";
import { MainText, FormText } from "@public/cms/types";
import { theme } from "@styles/Theme";
import { ChartOptions } from "chart.js";

export default function PensionResult(props: {
  locale: "nl" | "fr";
  resultData: OneManBusinessResults | DirectorResults;
  pensionFormData: PensionFormData;
  mainText: MainText;
  formText: FormText;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarVariant: Dispatch<SetStateAction<"success" | "error">>;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    datalabels
  );

  const graphData = {
    labels: [`${props.mainText.inkomen}`, `${props.mainText.legalPension}`],
    datasets: [
      {
        label: "Bruto-inkomen",
        backgroundColor: "rgba(8, 0, 112, 1)",
        data: [
          props.resultData.monthlyIncome.currentIncome,
          props.resultData.monthlyIncome.legalPension,
        ],
        barPercentage: 0.7,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: { size: fullscreen ? 12 : 18 },
        },
      },
      y: {
        ticks: {
          // Include a euro sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return props.locale == "nl"
              ? "€ " +
                  value.toLocaleString("fr-FR", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
              : value.toLocaleString("fr-FR", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + " €";
          },
          font: { size: fullscreen ? 12 : 18 },
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: function (value: number, context: any) {
          return props.locale == "nl"
            ? "€ " +
                value
                  .toLocaleString("nl-NL", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
                  .replace(/[\.]+/g, " ")
            : value
                .toLocaleString("nl-NL", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
                .replace(/[\.]+/g, " ") + " €";
        },
        color: "#FFFFFF",
        font: {
          weight: "bold" as "bold",
          size: fullscreen ? 12 : 18,
        },
      },
    },
    events: [],
  };

  const legalPensionGap =
    " -  " +
    Math.abs(
      props.resultData.monthlyIncome.currentIncome -
        props.resultData.monthlyIncome.legalPension
    ).toLocaleString("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: 8 }}>
        <Paper
          square
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Typography
            color={"primary"}
            fontWeight={"bold"}
            textAlign={"center"}
            variant="h2"
          >
            {props.mainText.resultScreenTitle}
          </Typography>
          <Typography color={"primary"} variant="h5">
            {props.mainText.resultScreenSubtitle}
          </Typography>

          <Box marginTop={5} marginBottom={5} height={"100%"} width={"100%"}>
            <Bar options={options} data={graphData} />
          </Box>
          <Divider component={"hr"} flexItem />
          <Box mb={2}>
            <Typography color={"primary"} variant="h5" mt={2} mb={2}>
              {props.mainText.pensionGapSubtitle}
            </Typography>
            <Typography color={"secondary"} variant="h2">
              {props.locale == "nl"
                ? "€ " + legalPensionGap
                : legalPensionGap + " €"}
            </Typography>
            <Typography>{props.mainText.pensionGapBefore}</Typography>
          </Box>
          <Divider component={"hr"} flexItem />
          <Box
            maxWidth={"sm"}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            <Typography>{props.mainText.resultScreenBodyOne}</Typography>
          </Box>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenModal(true)}
          >
            {props.mainText.resultScreenButtonSubmit}
          </Button>
        </Paper>
      </Container>
      <PersonalFormModal
        locale={props.locale}
        open={openModal}
        setOpen={setOpenModal}
        resultData={props.resultData}
        pensionFormData={props.pensionFormData}
        formText={props.formText}
        setSnackbarOpen={props.setSnackbarOpen}
        setSnackbarVariant={props.setSnackbarVariant}
      />
    </>
  );
}

//! dont remove until P&V made up their minds on the actual results.
//  <Grid
//    container
//    maxWidth={"sm"}
//    alignItems={"center"}
//    sx={{
//      paddingTop: 3,
//      paddingBottom: 3,
//    }}
//  >
//    <Grid item xs={12}>
//      <Typography color={"primary"} variant="h5">
//        {props.mainText.pensionGapSubtitle}
//      </Typography>
//      <Typography sx={{ mb: 2 }}>{props.mainText.pensionGapText}</Typography>
//    </Grid>

//    <Grid item xs={4}>
//      <Typography color={"primary"} variant="h2">
//        €{" "}
//        {Math.abs(
//          props.resultData.monthlyIncome.currentIncome -
//            props.resultData.monthlyIncome.legalPension
//        ).toLocaleString("nl-BE", { maximumFractionDigits: 2 })}
//      </Typography>
//      <Typography>{props.mainText.pensionGapBefore}</Typography>
//    </Grid>
//    <Grid item xs={4}>
//      <ArrowRightAlt fontSize="large" />
//    </Grid>
//    <Grid item xs={4}>
//      <Typography color={"primary"} variant="h2">
//        €{" "}
//        {(
//          props.resultData.monthlyIncome.currentIncome -
//          (props.resultData.monthlyIncome.legalPension +
//            props.resultData.monthlyIncome.rentSecondPillar +
//            props.resultData.monthlyIncome.rentThirdPillar)
//        ).toLocaleString("nl-BE", { maximumFractionDigits: 2 })}
//      </Typography>
//      <Typography>{props.mainText.pensionGapAfter}</Typography>
//    </Grid>
//  </Grid>;
