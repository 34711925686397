import React from "react";
import { FormControl, TextFieldProps, Typography } from "@mui/material";
import { FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormContext, Controller, RegisterOptions } from "react-hook-form";
import styled from "@emotion/styled";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

type TextFieldInputProps = {
  name: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  TextFieldProps?: Omit<TextFieldProps, "name" | "defaultValue">;
  label?: React.ReactNode;
};

function TextFieldInput({
  name,
  defaultValue,
  rules,
  TextFieldProps,
  label,
}: TextFieldInputProps) {
  const form = useFormContext();
  return (
    <Controller
      defaultValue={defaultValue ?? ""}
      control={form.control}
      name={name}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl fullWidth>
            <TextField
              {...field}
              error={fieldState.error ? true : false}
              label={label}
              disabled={formState.isSubmitting}
              {...TextFieldProps}
            />
            {fieldState.error && (
              <FormHelperText
                error
                sx={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    <WarningRoundedIcon sx={{ mr: 1 }} fontSize="small" />
                    {fieldState.error?.message}
                  </>
                }
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
}

export default TextFieldInput;
