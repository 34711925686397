import PensionTool from "@client/pages/pensionTool/Index";
import { Box } from "@mui/material";
import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const locale = useRouter().locale;
  return (
    <>
      <Head>
        {locale == "nl" && (
          <meta property="og:title" content="Pensioen simulator" />
        )}
        {locale == "nl" && (
          <meta
            property="og:url"
            content="https://tools.partena-professional.be"
          />
        )}
        {locale == "fr" && (
          <meta property="og:title" content="Simulateur de pension" />
        )}
        {locale == "fr" && (
          <meta
            property="og:url"
            content="https://tools.partena-professional.be/fr"
          />
        )}
        <title>pensioenoptimalisatie</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <PensionTool />
      </Box>
    </>
  );
};

export default Home;
